import i18n from '@/locale';
import chartOptions from '../chart/chart_options';
import request from '../utils';
import store from '../../store/index';
import helper from '../helper';
import common from '../component/common.js';

export function getMinuteLabel(item) {
  return new Promise((resolve) => {
    let hh;
    let mm;
    item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
    item.MinuteGrup < 10 ? mm = `0${item.MinuteGrup}` : mm = `${item.MinuteGrup}`;
    const date = `${hh}:${mm}`;
    resolve(date);
  });
}

export function getHourLabel(item) {
  return new Promise((resolve) => {
    let hh;
    item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
    const date = `${hh}`;
    resolve(date);
  });
}

export function getDailyLabel(item) {
  return new Promise((resolve) => {
    let m;
    let
      d;
    item.Mont < 10 ? (m = `0${item.Month}`) : (m = `${item.Month}`);
    item.Day < 10 ? (d = `0${item.Day}`) : (d = `${item.Day}`);
    const date = `${item.Year}-${m}-${d}`;
    resolve(date);
  });
}

export function getWeeklyLabel(item) {
  return new Promise((resolve) => {
    const date = `Hafta-${item.Week}`;
    resolve(date);
  });
}

export function getMonthlyLabel(item) {
  return new Promise((resolve) => {
    let m;
    item.Month < 10 ? (m = `0${item.Month}`) : (m = `${item.Month}`);
    const date = `${item.Year}-${m}`;
    resolve(date);
  });
}

export function getYearlyLabel(item) {
  return new Promise((resolve) => {
    resolve(item.Year);
  });
}

const setData = (item, measure, total) => {
  const formulesMeas = ['EquivalentTrees', 'CO2Avoided', 'BarrelsofOil'];

  let val = 0;

  if (formulesMeas.indexOf(measure) > -1) {
    let value = 0;
    item.DailyEnergy = parseFloat(item.DailyEnergy);

    if (item.DailyEnergy) {
      if (item.DailyEnergy > total) {
        value = item.DailyEnergy - total;
      }

      if (measure == 'EquivalentTrees') {
        val = Math.round(value / 230.73, 5);
      }

      if (measure == 'CO2Avoided') {
        val = Math.round(value * 0.65, 5);
      }

      if (measure == 'BarrelsofOil') {
        val = Math.round(value * 0.00058833, 5);
      }
    } else {
      val = 0;
    }
  } else {
    val = Math.round(parseFloat(item[measure]), 1);
  }

  return val;
};

const setSortType = (reportTypeModel) => {
  let sortType;

  if (reportTypeModel == 'Yearly') {
    sortType = 'Year';
  }
  if (reportTypeModel == 'Monthly') {
    sortType = 'Month';
  }

  if (reportTypeModel == 'Daily') {
    sortType = 'Day';
  }

  if (reportTypeModel == 'Hourly') {
    sortType = 'Hour';
  }
  if (reportTypeModel == 'Minute') {
    sortType = 'Hour';
  }
  return sortType;
};

export function dateListFonk(item, reportTypeModel) {
  return new Promise((resolve) => {
    if (reportTypeModel == 'Minute') {
      let hh;
      let mm;
      item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
      item.MinuteGrup < 10 ? mm = `0${item.MinuteGrup}` : mm = `${item.MinuteGrup}`;
      const date = `${hh}:${mm}`;
      resolve(date);
    }

    if (reportTypeModel == 'Hourly') {
      let hh;
      item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
      const date = `${hh}`;
      resolve(date);
    }

    if (reportTypeModel == 'Daily') {
      let m;
      let
        d;
      item.Month < 10 ? (m = `0${item.Month}`) : (m = `${item.Month}`);
      item.Day < 10 ? (d = `0${item.Day}`) : (d = `${item.Day}`);
      const date = `${item.Year}-${m}-${d}`;
      resolve(date);
    }
    if (reportTypeModel == 'Weekly') {
      const date = `Hafta-${item.Week}`;
      resolve(date);
    }

    if (reportTypeModel == 'Monthly') {
      let m;
      item.Month < 10 ? (m = `0${item.Month}`) : (m = `${item.Month}`);
      const date = `${item.Year}-${m}`;
      resolve(date);
    }
    if (reportTypeModel == 'Yearly') {
      resolve(item.Year);
    }
  });
}

const dateListFonks = (item, reportTypeModel) => {
  let date;

  if (reportTypeModel == 'Minute') {
    let hh;
    let mm;
    item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
    item.MinuteGrup < 10 ? mm = `0${item.MinuteGrup}` : mm = `${item.MinuteGrup}`;
    date = `${hh}:${mm}`;
  }

  if (reportTypeModel == 'Hourly') {
    let hh;
    item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
    date = `${hh}`;
  }

  if (reportTypeModel == 'Daily') {
    let m;
    let
      d;
    item.Month < 10 ? (m = `0${item.Month}`) : (m = `${item.Month}`);
    item.Day < 10 ? (d = `0${item.Day}`) : (d = `${item.Day}`);
    date = `${item.Year}-${m}-${d}`;
  }
  if (reportTypeModel == 'Weekly') {
    date = `Hafta-${item.Week}`;
  }

  if (reportTypeModel == 'Monthly') {
    let m;
    item.Month < 10 ? (m = `0${item.Month}`) : (m = `${item.Month}`);
    date = `${item.Year}-${m}`;
  }
  if (reportTypeModel == 'Yearly') {
    date = item.Year;
  }

  return date;
};

export function getStoreData() {
  return new Promise((resolve) => {
    store.watch(
      (state) => state.santral.generalStatsData,
      (newValue, oldValue) => {
        resolve(newValue);
      }, {
        deep: true,
      },
    );
  });
}

const getSantralMinuteDataChart = (formdata, measureList, text, chartTypeModel, reportTypeModel) =>
  new Promise((resolve) => {
    store.dispatch('setGeneralStatsData', formdata);
    getStoreData(formdata).then((_rawData) => {
      const sortType = setSortType(reportTypeModel);
      if (sortType === undefined || Object.keys(_rawData).length === 0) {
        const result = {
          options: {},
          desserts: [],
        };
        resolve(result);
        return;
      }

      let max_val = 0;
      const res = _rawData.sort((a, b) => {
        if (a[sortType] < b[sortType]) return -1;
        if (a[sortType] > b[sortType]) return 1;

        return 0;
      });

      res.forEach((item) => {
        measureList.forEach((measure) => {
          if (parseInt(item[measure.value]) > max_val) {
            max_val = parseInt(item[measure.value]);
          }
        });
      });

      const colors = ['#5470C6', '#91CC75', '#EE6666'];
      const dateList = [];
      const series = [];
      const measures = [];
      const desserts = [];
      const result = {};
      const yAxis = [];
      let offset = 0;
      let colorKey = 0;


      measureList.forEach((measure) => {
        measures.push(`${measure.text}(${measure.unit})`);
        const tempData = [];

        const total = 1;

        res.forEach((item) => {
          const obj = {};
          if (colorKey === 0) {
            const dd = dateListFonks(item, reportTypeModel);
            dateList.push(dd);
            obj.date = dd;

            // ....data table için döngü ......................................................
            const m_total = 0;
            measureList.forEach((m) => {
              const tempVal = setData(item, m.value, m_total);
              obj[m.value] = tempVal;
            });
            desserts.push(obj);
          }

          const chartTempVal = setData(item, measure.value, total);
          tempData.push(chartTempVal);
        });

        let chartType = chartTypeModel;
        if (chartTypeModel == 'line2') {
          chartType = 'line';
        }

        const obj = {
          name: `${measure.text}(${measure.unit})`,
          data: tempData,
          type: chartType,
        };

        let position = 'left';
        if (colorKey > 0) {
          position = 'right';
          obj.yAxisIndex = 1;
        }

        if (colorKey > 1) {
          offset += 110;
        }
        if (chartTypeModel == 'line2') {
          obj.areaStyle = {};
        }

        const max = parseInt(max_val) * 130 / 100;

        if (colorKey < 3) {
          yAxis.push({
            type: 'value',
            name: `${measure.text}(${measure.unit})`,
            min: 0,
            max,
            offset,
            position,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[colorKey],
              },
            },
            axisLabel: {
              formatter: `{value} ${measure.unit}`,
            },
          });
        }

        series.push(obj);
        colorKey += 1;
      });

      const options = chartOptions.line_options_multies(text, measures, dateList, series,
        colors, yAxis);

      result.options = options;
      result.desserts = desserts;

      resolve(result);
    });
  });
const getSantralPriceDataChart = (formdata, measureList, text, chartTypeModel, reportTypeModel,
  santralList) => new Promise((resolve) => {
  store.dispatch('setGeneralStatsData', formdata);
  getStoreData(formdata).then((_rawData) => {

    const sortType = setSortType(reportTypeModel);
    if (sortType === undefined || Object.keys(_rawData).length === 0) {
      const result = {
        options: {},
        desserts: [],
      };
      resolve(result);
      return;
    }

    let max_val = 0;
    const res = _rawData.sort((a, b) => {
      if (a[sortType] < b[sortType]) return -1;
      if (a[sortType] > b[sortType]) return 1;
      return 0;
    });

    const results = {};
    res.forEach((item) => {
      let m = item.Month < 10 ? `0${item.Month}` : `${item.Month}`;
      let d = item.Day < 10 ? `0${item.Day}` : `${item.Day}`;
      let time = `${item.Year}-${m}-${d}`;
      if (reportTypeModel == 'Daily') {
        time = `${item.Year}-${m}-${d}`;
      }
      if (reportTypeModel == 'Monthly') {
        time = `${item.Year}-${m}`;
      }
      if (reportTypeModel == 'Yearly') {
        time = item.Year;
      }

      if (!results.hasOwnProperty(time)) {
        results[time] = {};
      }

      measureList.forEach((measure) => {
        if (!results[time].hasOwnProperty(measure.value)) {
          results[time][measure.value] = 0;
        }

        results[time][measure.value] += parseFloat(item[measure.value]);

        if (parseInt(results[time][measure.value]) > max_val) {
          max_val = parseInt(results[time][measure.value]);
        }
      });
    });

    // let colors = ['#5470C6', '#91CC75', '#EE6666'];
    const colors = common.colors();
    const dateList = [];
    const series = [];
    const measures = [];
    const desserts = [];
    const result = {};
    const yAxis = [];
    let offset = 0;
    let colorKey = 0;




    measureList.forEach((measure) => {
      measures.push(`${measure.text}(${measure.unit})`);
      const tempData = [];

      const total = 1;
      Object.keys(results).forEach((time) => {

        const obj = {};
        if (colorKey === 0) {
          dateList.push(time);
          obj.date = time;


          const m_total = 0;
          measureList.forEach((m) => {

            const tempVal = setData(results[time], m.value, m_total);

            obj[m.value] = tempVal;
          });

          desserts.push(obj);
        }

        const chartTempVal = setData(results[time], measure.value, total);
        tempData.push(chartTempVal);
      });

    
      let chartType = chartTypeModel;
      if (chartTypeModel == 'line2') {
        chartType = 'line';
      }

      const obj = {
        name: `${measure.text}(${measure.unit})`,
        data: tempData,
        type: chartType,
      };

      const position = 'left';

      if (colorKey > 1) {
        offset += 110;
      }
      if (chartTypeModel == 'line2') {
        obj.areaStyle = {};
      }

      const max = parseInt(max_val) * 130 / 100;

      if (colorKey < 1) {
        yAxis.push({
          type: 'value',
          name: measure.text,
          min: 0,
          max,
          offset,
          position,
          show: true,
          axisLabel: {
            formatter: '{value} ',
            show: true,
          },
        });
      }

      series.push(obj);
      colorKey += 1;
    });

    const options = chartOptions.line_options_multies(text, measures, dateList, series,
      colors, yAxis);

    result.options = options;
    result.desserts = desserts;

    resolve(result);
  });
});

const colors = [
  '#e22129',
  '#f27425',
  '#fbd00e',
  '#a7c13a',
  '#51a146',
  '#2ea3a3',
  '#2480c3',
  '#8d275f',
  '#FF9661',
  '#46C7A8',
  '#68C17A',
  '#F18EE1',
  '#72AEFF',
  '#FF8A7E',
  '#ED0A3F',
  '#FFFF66',
  '#EE34D2',
  '#FF8833',
  '#299617',
  '#5DADEC',
  '#8D4E85',
  '#0066FF',
  '#6a2d8d',
  '#E62E6B',
  '#87FF2A',
  '#FD5B78',
  '#C5E17A',
  '#FF9980',
  '#B5B35C',
  '#8FD8D8',
  '#FF6D3A',
  '#B05C52',
  '#828E84',
  '#C53151',
  '#4F209C',
  '#2E79DD',
  '#6BE3D5',
  '#DC3E41',
  '#E56725',
  '#FCCE38',
];

const getSantralComparisonMinuteChart = (formdata, measure, text, chartTypeModel,
  reportTypeModel, santralModel) => new Promise((resolve) => {
  const tempMinute = ['Minute', 'Hourly'];

  store.dispatch('setGeneralStatsData', formdata);
  getStoreData(formdata).then((_rawData) => {
    const sortType = setSortType(reportTypeModel);

    const res = _rawData.sort((a, b) => {
      if (a[sortType] < b[sortType]) return -1;
      if (a[sortType] > b[sortType]) return 1;
      return 0;
    });

    const desserts = [];
    const dessert = {};
    const dateList = [];
    const series = [];
    const result = {};
    const yAxis = [];

    let colorKey = 0;

    santralModel.forEach((santral) => {
      const tempData = [];

      let total = 0;
      let measure_total = 0;
      let temp_Val = 0;

      res.forEach((item) => {
        let tempVal;
        const obj = {};
        obj.date = dateListFonks(item, reportTypeModel);

        const m_total = 0;

        if (santral == item.santral) {
          if (measure.value === 'DailyEnergy') {
            const tempVals = setData(item, measure.value, m_total);

            if (tempMinute.indexOf(reportTypeModel) > -1) {
              tempVal = tempVals - temp_Val;
              if (tempVal < 0) {
                tempVal = 0;
              }
              temp_Val = tempVals;
            } else {
              tempVal = tempVals;
            }
          } else {
            tempVal = setData(item, measure.value, m_total);
          }

          obj[santral] = tempVal;
          dessert[obj.date] = {
            ...dessert[obj.date],
            ...obj,
          };

          tempData.push(tempVal);
          if (!item[measure.value]) {
            total = parseInt(item.DailyEnergy);
          } else {
            total = parseInt(item[measure.value]);
            if (total > measure_total) {
              measure_total = total;
            }
          }
        }

        const dd = dateListFonks(item, reportTypeModel);

        if (formdata.timeList.indexOf(dd)) {
          if (colorKey === 0) {
            if (dateList.indexOf(dd) < 0) {
              dateList.push(dd);
            }
          }
        }
      });

      const obj = {
        name: santral,
        data: tempData,
        type: chartTypeModel,
      };

      yAxis.push({
        type: 'value',
        name: measure.value,
      });

      series.push(obj);
      colorKey += 1;
    });

    Object.keys(dessert).forEach((key) => {
      desserts.push(dessert[key]);
    });

    const options = chartOptions.line_options_multies_1(text, santralModel, dateList,
      series, colors, yAxis);

    result.options = options;
    result.desserts = desserts;

    resolve(result);
  });
});

const getSantralMinuteDataDash = (formdata, measureList, text, chartTypeModel, reportTypeModel) =>
  new Promise((resolve) => {
    const url = '/panel/santral/minuterpower';
    const tempData = request.Api(url, formdata, 'post');
    tempData.then((_rawData) => {

      if (_rawData.status !== 200) {
        resolve({});
        return;
      }
      if (_rawData.data.success === 'false') {
        resolve({});
        return;
      }
      const sortType = 'Hour';

      const colors = ['#5470C6', '#91CC75', '#EE6666'];
      const res = _rawData.data.data.sort((a, b) => {
        if (a[sortType] < b[sortType]) return -1;
        if (a[sortType] > b[sortType]) return 1;
        return 0;
      });

      const series = [];
      const measures = [];
      const timeField = [];
      const yAxis = [];
      const max_val = {};
      const tempTimeData = {};

      res.forEach((item) => {
        let hh;
        let mm;
        item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
        item.MinuteGrup < 10 ? mm = `0${item.MinuteGrup}` : mm = `${item.MinuteGrup}`;
        const date = `${hh}:${mm}`;
        tempTimeData[date] = item;

        measureList.forEach((measure) => {
          const tmp1 = parseInt(item[measure.value]);

          if (!max_val[measure.value]) {
            max_val[measure.value] = 0;
          }

          if (tmp1 > max_val[measure.value]) {
            max_val[measure.value] = tmp1;
          }
        });
      });


      let colorKey = 0;
      let offset = 0;
      let total_Val = 0;
      const step_time = helper.getSantralStep(formdata.condiniton.santral);

      measureList.forEach((measure) => {
        measures.push(`${measure.value} ${measure.unit}`);
        const tempData = [];

        const total = 0;
        const minute = 0;
        const hour = 5;
        const vals1 = 0;

        for (let i = hour; i <= 20; i++) {
          for (let x = minute; x < 60; x += step_time) {
            let h;
            let
              m;
            i < 10 ? h = `0${i}` : h = `${i}`;
            x < 10 ? m = `0${x}` : m = `${x}`;
            const time = `${h}:${m}`;

            if (colorKey == 0) {
              timeField.push(time);
            }

            let vals = 0;
            if (tempTimeData[time]) {
              if (tempTimeData[time][measure.value]) {
                vals = tempTimeData[time][measure.value];
              }
            }

            if (!vals) {
              vals = 0;
            }
            if (measure.value === 'DailyEnergy') {
              if (tempTimeData[time]) {
                if (total_Val < parseFloat(vals)) {
                  total_Val = parseFloat(vals);
                }
                tempData.push(Math.round(vals - total, 1));
              } else {
                tempData.push(0);
              }
            } else if (tempTimeData[time]) {
              if (total_Val < parseFloat(vals)) {
                total_Val = parseFloat(vals);
              }
              tempData.push(Math.round(vals, 1));
            } else {
              tempData.push(0);
            }
          }
        }

        let chartType = chartTypeModel;
        if (chartTypeModel == 'line2') {
          chartType = 'line';
        }

        const obj = {
          name: `${i18n.t(measure.value)}(${measure.unit})`,
          data: tempData,
          type: chartType,
        };

        let position = 'left';
        if (colorKey > 0) {
          position = 'right';
          obj.yAxisIndex = colorKey;
        }

        if (colorKey > 1) {
          offset += 110;
        }
        if (chartTypeModel == 'line2') {
          obj.areaStyle = {};
        }

        total_Val = max_val[measure.value] * 120 / 100;

        const x_Axis = {
          type: 'value',
          name: `${i18n.t(measure.value)}(${measure.unit})`,
          min: 0,
          max: total_Val,
          offset,
          position,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[colorKey],
            },
          },
          axisLabel: {
            formatter: `{value} ${measure.unit}`,
          },
        };
        if (colorKey > 1) {
          x_Axis.show = false;
        }

        yAxis.push(x_Axis);
        series.push(obj);
        colorKey += 1;
      });

      const options = chartOptions.line_options_multies(text, measures, timeField, series,
        colors, yAxis);

      resolve(options);
    });
  });

const getSantralMinuteData_Dash = (formdata, measureList, text, chartTypeModel, reportTypeModel) =>
  new Promise((resolve) => {
    const url = '/panel/santraller/minuteGrup';
   

    const tempData = request.Api(url, formdata, 'post');
    tempData.then((_rawData) => {
      

      if (_rawData.status !== 200) {
        resolve({});
        return;
      }
      if (_rawData.data.success === 'false') {
        resolve({});
        return;
      }
      const sortType = 'Hour';

      const colors = ['#5470C6', '#91CC75', '#EE6666'];
      const res = _rawData.data.data.sort((a, b) => {
        if (a[sortType] < b[sortType]) return -1;
        if (a[sortType] > b[sortType]) return 1;
        return 0;
      });

      const series = [];
      const measures = [];
      const timeField = [];
      const yAxis = [];
      const max_val = {};

      const tempTimeData = {};
      res.forEach((item) => {
        let hh;
        let mm;
        item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
        item.MinuteGrup < 10 ? mm = `0${item.MinuteGrup}` : mm = `${item.MinuteGrup}`;
        const date = `${hh}:${mm}`;
        tempTimeData[date] = item;

     

        measureList.forEach((measure) => {
          const tmp1 = parseInt(item[measure.value]);

          if (!max_val[measure.value]) {
            max_val[measure.value] = 0;
          }

          if (tmp1 > max_val[measure.value]) {
            max_val[measure.value] = tmp1;
          }
        });
      });

      let colorKey = 0;
      let offset = 0;
      let total_Val = 0;

      measureList.forEach((measure) => {
        measures.push(`${measure.value} ${measure.unit}`);
        const tempData = [];

        const total = 0;
        const minute = 0;
        const hour = 5;

        for (let i = hour; i <= 20; i++) {
          for (let x = minute; x < 60; x += 5) {
            let h;
            let
              m;
            i < 10 ? h = `0${i}` : h = `${i}`;
            x < 10 ? m = `0${x}` : m = `${x}`;
            const time = `${h}:${m}`;

            if (colorKey == 0) {
              timeField.push(time);
            }

            if (tempTimeData[time]) {
              tempData.push(Math.round(tempTimeData[time][measure.value], 1));
            } else {
              tempData.push(0);
            }
          }
        }

        let chartType = chartTypeModel;
        if (chartTypeModel == 'line2') {
          chartType = 'line';
        }

        const obj = {
          name: `${i18n.t(measure.value)}(${measure.unit})`,
          data: tempData,
          type: chartType,
        };

        let position = 'left';
        if (colorKey > 0) {
          position = 'right';
          obj.yAxisIndex = colorKey;
        }

        if (colorKey > 1) {
          offset += 110;
        }
        if (chartTypeModel == 'line2') {
          obj.areaStyle = {};
        }

        total_Val = max_val[measure.value] * 120 / 100;

        const x_Axis = {
          type: 'value',
          name: `${i18n.t(measure.value)}(${measure.unit})`,
          min: 0,
          max: total_Val,
          offset,
          position,
          axisLine: {
            show: true,
            lineStyle: {
              color: common.colors[colorKey],
            },
          },
          axisLabel: {
            formatter: `{value} ${measure.unit}`,
          },
        };
        if (colorKey > 1) {
          x_Axis.show = false;
        }

        yAxis.push(x_Axis);
        series.push(obj);
        colorKey += 1;
      });

      const options = chartOptions.line_options_multies(text, measures, timeField, series,
        colors, yAxis);

      resolve(options);
    });
  });

export default {
  getSantralMinuteDataChart,
  getSantralMinuteDataDash,
  getSantralMinuteData_Dash,
  getSantralComparisonMinuteChart,
  getSantralPriceDataChart,
};
