<template>
  <div>
    <v-row class="mr-3 mt-1 ml-2">
      <v-col
        v-for="(measure, key) in measureList"
        :key="key"
        cols="12"
        sm="3"
        md="3"
        class="pr-0 mt-0 pt-0 m-0"
      >
        <v-card>
          <echart
            :id="measure.value + 'santral-live-data'"
            class-name="widget-content"
            height="210px"
            width="260px"
            :chart-data="chartData[measure.value]"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Echart from '@/views/components/Echart/Chart.vue';
import * as Santral from '@/api/Dashboard/Santral';
import i18n from '@/locale';
import moment from 'moment';

export default {
  components: {
    Echart,
  },
  props: ['selectedsantral'],
  data: () => ({
    chartData: {},
     riseze: "false",
  }),
  computed: {
    measureList() {
      return [
        {
          value: 'PerformanceRatio',
          text: i18n.t('PerformanceRatio'),
          type: 'float',
          unit: '%',
        },
        {
          value: 'IstantaneousPower',
          text: i18n.t('IstantaneousPower'),
          type: 'float',
          unit: 'Kw',
        },
        {
          value: 'Irr',
          text: i18n.t('Irradiation'),
          type: 'float',
          unit: 'mv',
        },
        {
          value: 'Temp',
          text: i18n.t('Temperature'),
          type: 'float',
          unit: '°C',
        },
      ];
    },
  },
  watch: {
     $route: function (from, to) {
      this.riseze = "true";
    },
    selectedsantral() {
      console.log('Selectedsantral');
      console.log(this.selectedsantral);
      if (this.selectedsantral !== null && this.selectedsantral !== undefined) {
        const StatsMeasure = this.$store.getters.santralInfo;

        if (StatsMeasure[this.selectedsantral] !== undefined) {
          this.getSantralLiveData();
        }
      }
    },
  },
  created() {},
  mounted() {
    const myVar1 = setInterval(myTimer1, 1000);

    const self = this;
    function myTimer1() {
      const StatsMeasure = self.$store.getters.santralInfo;

      if (
        Object.keys(StatsMeasure).length > 0
        && StatsMeasure !== undefined
        && self.selectedsantral !== undefined
        && self.selectedsantral != 'null'
      ) {
        clearInterval(myVar1);
        self.getSantralLiveData();
      }
    }

    setInterval(this.getSantralLiveData, 300000);
  },

  methods: {
    getSantralLiveData() {
      const formdata = {};
      formdata.santral = this.setStatsMeasure();
      formdata.sensor = this.setSensorMeasure();
      console.log(formdata);
      const minuteData = Santral.default.getSantralLiveData(
        formdata,
        this.measureList,
      );

      minuteData.then((res) => {
        this.chartData = res;
        this.$store.dispatch('setGeneralUpdateTime', res.updateTime);
      });
    },

    setSensorMeasure() {
      const sensor = this.$store.getters.sensorInfo;
      const date = moment().format('YYYY-MM-DD');
      return {
        condiniton: {
          santral: this.selectedsantral,
          skip: 0,
          limit: 1,
          orderBys: [{ fieldName: 'datetimestamp', sortOrder: 'desc' }],
          count: '0',
          date,
          sensor: sensor[this.selectedsantral],
        },
      };
    },

    setStatsMeasure() {
      const generalStatsMeasureList = [
        {
          value: 'PerformanceRatio',
          text: i18n.t('PerformanceRatio'),
          type: 'float',
          unit: '%',
        },
        {
          value: 'IstantaneousPower',
          text: i18n.t('IstantaneousPower'),
          type: 'float',
          unit: 'Kw',
        },
      ];
      const measures = [];

      generalStatsMeasureList.forEach((item) => {
        measures.push(item.value);
      });
      const date = moment().format('YYYY-MM-DD');

      return {
        condiniton: {
          santral: this.selectedsantral,
          skip: 0,
          limit: 1,
          orderBys: [{ fieldName: 'datetimestamp', sortOrder: 'desc' }],
          count: '0',
          measures,
          date,
        },
      };
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style>
.v-card__subtitle {
  margin-bottom: -15px !important;
}
</style>
