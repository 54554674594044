<template>
  <div>
    <v-container class="container--fluid">
      <v-row dense>
        <SantralSelect @selectedsantral="selectedSantralFunc" />
      </v-row>

      <v-row dense>
        <InstantDataGauge :selectedsantral="santralmodel" />
      </v-row>
      <v-col
        class="d-flex"
        cols="12"
        sm="12"
        md="12"
      >
        <SantralStats :selectedsantral="santralmodel" />
        <SantralInfo :selectedsantral="santralmodel" />
      </v-col>
      <v-row dense>
        <DailyMinuteData :selectedsantral="santralmodel" />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SantralSelect from '@/views/components/Santrals/SantralSelect.vue';
import InstantDataGauge from '@/views/components/Santrals/InstantDataGauge.vue';
import SantralStats from '@/views/components/Santrals/SantralStats.vue';
import DailyMinuteData from '@/views/components/Santrals/DailyMinuteData.vue';
import SantralInfo from '@/views/components/Santrals/SantralInfo.vue';

export default {
  components: {
    SantralSelect,
    InstantDataGauge,
    SantralStats,
    DailyMinuteData,
    SantralInfo,
  },
  data: () => ({
    santralmodel: 'null',
  }),
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    selectedSantralFunc(item) {
      this.santralmodel = item;
    },
  },
};
</script>

<style>
.v-card__subtitle {
  margin-bottom: -15px !important;
}
</style>
