<template>
  <v-col
    cols="12"
    sm="3"
    md="3"
    class="mt-0 pt-0 mb-0 pb-0"
  >
    <v-card
      v-if="componentKey"
      class="mt-0 pt-0 mb-0 pb-0"
      max-width="400"
    >
      <v-data-table
        dense
        :headers="santral_headers"
        :items="santral_items"
        hide-default-header
        hide-default-footer
        class="elevation-1"
      />
    </v-card>
  </v-col>
</template>

<script>
import moment from 'moment';

export default {
  props: ['selectedsantral'],
  data: () => ({
    santral_items: [],
    componentKey: 1,
  }),
  computed: {
    santral_headers() {
      return [
        { text: 'type', value: 'type' },
        { text: 'value', value: 'value' },
      ];
    },
  },
  watch: {
    selectedsantral() {
      if (this.selectedsantral !== null && this.selectedsantral !== undefined) {
        this.setInverterQuantity();
        this.setİnverterModuleQuantity();
        this.setStringsQuantity();
        this.setSensorQuantity();
      }
    },
  },
  created() {},
  mounted() {
    const self = this;
    this.componentKey += 1;

    const myVarİnv = setInterval(myTimerİnv, 1000);
    function myTimerİnv() {
      const { inverterList } = self.$store.getters;
      if (
        Object.keys(inverterList).length > 0
        && self.selectedsantral !== undefined
      ) {
        clearInterval(myVarİnv);
        self.santral_items = [
          {
            type: 'İnverter Sayısı',
            value: 0,
          },
          {
            type: 'Strings Sayısı',
            value: 0,
          },
          {
            type: 'Modül Sayısı',
            value: 0,
          },
          {
            type: 'Sensor Sayısı',
            value: 0,
          },
          {
            type: 'Güncelleme zamanı',
            value: moment().format('YYYY-MM-DD HH:mm:s'),
          },
        ];
        self.setInverterQuantity();
      }
    }

    const myVarİnvMod = setInterval(myTimerİnvMod, 1000);
    function myTimerİnvMod() {
      const { inverterInfo } = self.$store.getters;
      if (
        Object.keys(inverterInfo).length > 0
        && self.selectedsantral !== undefined
      ) {
        clearInterval(myVarİnvMod);

        self.setİnverterModuleQuantity();
      }
    }

    const myVarString = setInterval(myTimerString, 1000);
    function myTimerString() {
      const { stringboxInfo } = self.$store.getters;
      if (
        Object.keys(stringboxInfo).length > 0
        && self.selectedsantral !== undefined
      ) {
        clearInterval(myVarString);
        self.setStringsQuantity();
      }
    }

    const myVarSensor = setInterval(myTimerSensor, 1000);

    function myTimerSensor() {
      const { sensorList } = self.$store.getters;
      if (
        Object.keys(sensorList).length > 0
        && self.selectedsantral !== undefined
      ) {
        clearInterval(myVarSensor);

        self.setSensorQuantity();
      }
    }

    this.santralgeneralupdate();
    setInterval(this.santralgeneralupdate, 300000);
  },
  methods: {
    santralgeneralupdate() {
       
      if( this.santral_items.length > 0){
        this.santral_items[4].value = this.$store.getters.santralgeneralupdate;
      }
    },

    setInverterQuantity() {
      const { inverterList } = this.$store.getters;

      let inverterQuantity = 0;
      if (
        Object.keys(inverterList).length > 0
        && this.selectedsantral !== undefined
      ) {
        inverterQuantity = inverterList[this.selectedsantral].length;
        this.santral_items[0].value = inverterQuantity;
      }
    },
    setİnverterModuleQuantity() {
      const { inverterInfo } = this.$store.getters;
      let inverterModuleQuantity = 0;
      if (
        Object.keys(inverterInfo).length > 0
        && this.selectedsantral !== undefined
      ) {
        Object.keys(inverterInfo[this.selectedsantral]).forEach((i) => {
          if (
            inverterInfo[this.selectedsantral][i].hasOwnProperty(
              'ModuleQuantity',
            )
          ) {
            inverterModuleQuantity
              += inverterInfo[this.selectedsantral][i].ModuleQuantity;
          }
        });

        this.santral_items[2].value = inverterModuleQuantity;
      }
    },

    setStringsQuantity() {
      const { stringboxInfo } = this.$store.getters;
      let stringsQuantity = 0;
      if (
        Object.keys(stringboxInfo).length > 0
        && this.selectedsantral !== undefined
      ) {
        Object.keys(stringboxInfo[this.selectedsantral]).forEach((i) => {
          if (
            stringboxInfo[this.selectedsantral][i].hasOwnProperty(
              'stringQuantity',
            )
          ) {
            stringsQuantity
              += stringboxInfo[this.selectedsantral][i].stringQuantity;
          }
        });

        this.santral_items[1].value = stringsQuantity;
      }
    },
    setSensorQuantity() {
      const { sensorList } = this.$store.getters;
      let sensorQuantity = 0;
      if (
        Object.keys(sensorList).length > 0
        && this.selectedsantral !== undefined
      ) {
        sensorQuantity += sensorList[this.selectedsantral].length;

        this.santral_items[3].value = sensorQuantity;
      }
    },
  },
};
</script>

<style scoped>
</style>
